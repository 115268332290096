import request from '@/utils/request'

export const getProductListAPI = (params) => {
  return request({
    url: 'Product/list',
    method: 'get',
    params
  })
}
export const getContentListAPI = (params) => {
  return request({
    url: 'Product/product_lists',
    method: 'get',
    params
  })
}
export const getCommonTagsAPI = (params) => {
  return request({
    url: 'Product/getCommonTags',
    method: 'get',
    params
  })
}
export const getQrcodeAPI = (params) => {
  return request({
    url: 'Login/qrcode',
    method: 'get',
    params
  })
}
export const trainingInRotation = (params) => {
  return request({
    url: 'Login/isSubscribe',
    method: 'get',
    params
  })
}
export const getProductInfoAPI = (params) => {
  return request({
    url: 'Product/products_id',
    method: 'get',
    params
  })
}
export const getProductdetailsAPI = (params) => {
  return request({
    url: 'Product/product_details',
    method: 'get',
    params
  })
}
export const getUserInfoAPI = (params) => {
  return request({
    url: 'Login/UserInfo',
    method: 'get',
    params
  })
}
export const feedback = (data) => {
  return request({
    url: 'Login/feedback_add',
    method: 'post',
    data
  })
}
export const getOrderComboAPI = (params) => {
  return request({
    url: 'Product/order_combo',
    method: 'get',
    params
  })
}
export const orderAddAPI = (data) => {
  return request({
    url: 'Login/orderAdd',
    method: 'post',
    data
  })
}
export const getOrderStatusAPI = (params) => {
  return request({
    url: 'Login/order_Status',
    method: 'get',
    params
  })
}
export const getOrderListAPI = (params) => {
  return request({
    url: 'Login/orderInfo',
    method: 'get',
    params
  })
}
