<template>
  <div>
    <div class="sidebar" :class="[isShow ? 'sidebar_move' : '']">
      <div class="sidebar_top">
        <el-popover
          placement="right"
          trigger="hover"
          width="100px"
          v-model="popoverVisible"
        >
          <div class="goback" @click="handleGoBack">
            <img
              src="../../assets/home.png"
              style="width: 22px; height: 22px"
            />
            <div style="margin-left: 10px">回到首页</div>
          </div>
          <template #reference>
            <img
              src="../../assets/椭圆 1.png"
              style="width: 73px; height: 73px"
            />
          </template>
        </el-popover>
        <div class="sidebar_top_title">软件总管</div>
      </div>
      <div class="sidebarleft">
        <el-menu
          class="el-menu-vertical-demo"
          :collapse="false"
          text-color="#9FA7AF;"
          style="margin-bottom: 100px"
          :unique-opened="true"
          @open="handleOpen"
        >
          <el-submenu
            v-for="(item, index) in productList"
            :index="index + ''"
            :collapse="false"
            :key="item.id"
          >
            <template slot="title">
              <img :src="item.icon_image" style="width: 30px; height: 30px" />
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item
              :index="item.id + '-' + idx"
              v-for="(ite, idx) in item.children"
              :key="ite.id"
              @click="handleCLickSon(ite.id, ite.name, item.name, ite)"
            >
              <template slot="title">
                <img :src="item.icon_image" style="width: 25px; height: 25px" />
                <span>{{ ite.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="sidebarright" @click="handelClose"></div>
      <div></div>
      <div class="about">
        <i class="el-icon-user"></i>
        关于我们
      </div>
    </div>
    <div class="drover" @click="handleOpenDrawer">
      <i class="el-icon-s-unfold"></i>
    </div>
  </div>
</template>

<script>
import { getProductListAPI } from "@/api/index.js";

export default {
  name: "Sidbar",
  data() {
    return {
      productList: [],
      popoverVisible: false,
      activeMenuItem: "",
      activeMenuItemArray: [],
      isShow: false,
      isCollapsed: false,
    };
  },
  created() {
    this.getProductList();
  },

  watch: {
    $route(to, from) {
      if (to.path.startsWith("/product") && from.fullPath === "/") {
        this.activeMenuItemArray = [];
        console.log(this.$store.state.activeMenuItem);
        this.activeMenuItemArray.push(this.$store.state.activeMenuItem);
      } else if (to.path.startsWith("/")) {
        this.activeMenuItemArray = [];
      }
    },
  },
  methods: {
    handleCLickSon(id, name, pname, ite) {
      console.log(id);
      console.log(this.$route.query.id);
      console.log(ite);
      if (this.$route.query.id) {
        if (String(id) !== this.$route.query.id) {
          if (this.$route.path === "/product") {
            this.$router.push({
              path: "/product",
              query: {
                id: id,
                name,
                pname,
              },
            });
          }
        }
      } else {
        this.$router.push({
          path: "/product",
          query: {
            id: id,
            name,
            pname,
          },
        });
      }
    },
    async getProductList() {
      const res = await getProductListAPI();
      this.productList = res.data;
    },
    handleGoBack() {
      if (this.$route.path !== "/") {
        this.$router.push({
          path: "/",
        });
      }
    },
    handleOpen(key) {
      if (this.$route.path === "/") {
        const targetElement = document.getElementById(key);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      } else if (this.$route.path === "/userinfo") {
        this.$router.push({
          path: "/",
        });
        localStorage.setItem("scroll", key);
      }
      this.isShow = false;
    },
    handleOpenDrawer() {
      this.isShow = !this.isShow;
    },
    handelClose() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  left: 0;
  padding-bottom: 30px;
  overflow-y: scroll;
}
.sidebarleft {
  width: 200px;
  height: 100vh;
  position: fixed;
  left: 0;
  padding-bottom: 30px;
  overflow-y: scroll;
}
.sidebarleft::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
.sidebar::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
.sidebar_top {
  width: 100%;
  height: 133px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar_top_title {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
.product {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_title {
  display: flex;
  align-items: center;
  color: #9fa7af;
}

.goback {
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-family: fangyuan;
  display: flex;
  align-items: center;
}
.about {
  position: fixed;
  bottom: 0;
  color: #a4acb3;
  font-size: 14px;
  font-family: fangyuan;
  text-align: center;
  width: 200px;
  padding: 10px 0;
  background-color: #fff;
}
.drover {
  display: none;
}
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .about {
    display: none;
  }
  .sidebar_top {
    display: none;
  }
  .drover {
    display: block;
    position: fixed;
    top: 20px;
    left: 10px;
    font-size: 35px;
    color: #fff;
    font-family: fangyuan;
  }
  .sidebar_move {
    display: block;
    height: 100vh;
    z-index: 9999;
    width: 100%;
  }
  .sidebarleft {
    height: 100%;
    background-color: #fff;
  }
  .sidebarright {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
