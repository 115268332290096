<template>
  <div class="container">
    <div class="content" @scroll="handeleScrollNew">
      <div class="content_left">
        <div class="content_left_top">
          <div>{{ name }}</div>
          <div class="content_left_top_route">
            <div>
              {{ pname }}
            </div>
            <i class="el-icon-arrow-right"></i>
            <div style="content_left_top_route_detaildname">
              {{ details.name }}
            </div>
          </div>
        </div>
        <div class="version">
          <div
            class="version_item"
            v-for="(item, index) in classification"
            :key="index"
            @mouseenter="showMask(item.id)"
            @mouseleave="hideMask()"
            @click="handleCLick(item, index)"
          >
            <div class="version_item_left">
              <div style="position: relative">
                <img :src="item.goal_image" style="width: 100%" />
                <div class="version_item_hover" v-show="isMaskVisible(item.id)">
                  <img src="@/assets/下载.png" />
                </div>
              </div>
            </div>
            <div class="version_item_right">
              <div class="version_item_right_top">
                <div>{{ item.title }}</div>
                <div>{{ item.versions }}</div>
              </div>
              <div class="version_item_right_bottom">大小: {{ item.size }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_right">
        <div
          class="content_right_top"
          :class="[isHidden ? 'content_right_top_scroll' : '']"
        >
          <div class="content_right_top__title">
            <div class="img_box">
              <img :src="details.article_image" class="content_right_top_img" />
            </div>
            <div class="info_box">
              <div class="details_name">
                {{ details.name }}
              </div>
              <div class="details_intro">
                {{ details.intro }}
              </div>
            </div>
          </div>
          <div class="down">
            <div class="down_load" @click="handledown">
              <i class="el-icon-download"></i>极速下载
            </div>
            <div class="set" @click="handleSet">远程安装</div>
          </div>
          <div class="content_right_top_bottom">
            <div>
              <span style="color: red">郑重声明</span
              >：各位学员，软件总管本身不提供下载资源，以下资源地址均为网友整理提供，并且需离开本站，请各位学员谨慎选择，我站将不提供任何保障。<span
                class="report_move"
              >
                另：如这些资源地址有违规或侵权行为，请点击
                <span class="report" @click="handleReportDialog">我要举报</span
                >，我方将在确认后第一时间清除链接。
              </span>
            </div>
          </div>
        </div>
        <div
          class="content_right_bottom"
          :class="[isHidden ? 'content_right_bottom_scroll' : '']"
        >
          <div style="margin-left: -5px">
            <img src="@/assets/安装教程.png" />
          </div>
          <div style="margin-bottom: 40px">
            <table>
              <tr>
                <td style="background-color: #f8f8f8; width: 25%">语言</td>
                <td style="width: 30%">简体中文</td>
                <td style="background-color: #f8f8f8; width: 25%">大小</td>
                <td style="width: 30%">3.2G</td>
              </tr>
              <tr>
                <td style="background-color: #f8f8f8; width: 25%">系统要求</td>
                <td style="width: 30%">Win10(1903)及以上版本,64 位操作系统</td>
                <td style="background-color: #f8f8f8; width: 25%">硬件要求</td>
                <td style="width: 30%">CPU@2+GHz,RAM@4G 或更高</td>
              </tr>
            </table>
          </div>
          <div v-html="details.content" class="ismobile"></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer__item" @click="handleCustomer">
        <i
          class="el-icon-headset"
          style="font-size: 25px; margin-bottom: 10px"
        ></i>
        联系客服
      </div>
      <div class="footer__item" @click="handleReportDialog">
        <i
          class="el-icon-edit"
          style="font-size: 25px; margin-bottom: 10px"
        ></i>
        用户反馈
      </div>
      <div class="footer__item" @click="handleJumpUser">
        <i
          class="el-icon-monitor"
          style="font-size: 25px; margin-bottom: 10px"
        ></i>
        用户中心
      </div>

      <div class="footer__item" @click="scrollToTop">
        <i
          class="el-icon-upload2"
          style="font-size: 25px; margin-bottom: 10px"
        ></i>
        返回顶部
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      ref="dialog"
      :show-close="false"
      @close="handleDialogClose"
    >
      <div class="setdialog">
        <div class="setdialog_left">
          <div class="setdialog_left_img">
            <img src="@/assets/白logo.png" />
          </div>
          <div class="setdialog_left_text">
            <div class="setdialog_left_text_item">10000+软件种类齐全</div>
            <div class="setdialog_left_text_item">24小时客服在线回复</div>
            <div class="setdialog_left_text_item">专人远程安装通道</div>
            <div class="setdialog_left_text_item">极智云盘极速下载</div>
          </div>
        </div>
        <div class="setdialog_right">
          <div style="display: flex">
            <div>
              <div style="display: flex">
                <div>
                  <img src="@/assets/元素2.png" />
                </div>
                <div class="setdialog_right_top_text">远程服务</div>
              </div>
              <div style="margin-top: -10px">
                <img src="@/assets/元素.png" />
              </div>
            </div>
            <div class="setdialog_right_top_text_arrow">
              选择套餐
              <i class="el-icon-right"></i>
              在线购买
              <i class="el-icon-right"></i>
              立即服务
              <i class="el-icon-right"></i>
              售后保障
            </div>
          </div>
          <div class="user_info">
            <div><img :src="avatar" /></div>
            <div style="margin-left: 10px">
              <div>ID:{{ id }}</div>
              <div>{{ nickname }}</div>
            </div>
          </div>
          <div class="price">
            <div
              class="price_item"
              v-for="(item, index) in priceData"
              :key="index"
              @click="handleClickPrice(item)"
            >
              <div class="price_item_top">{{ item.type }}</div>
              <div class="price_item_center">￥{{ item.price }}</div>
              <div class="price_item_bottom">
                <div
                  v-for="(ite, idx) in item.name"
                  :key="idx"
                  class="price_item_bottom_item"
                >
                  ·{{ ite.name }}
                </div>
              </div>
              <div class="price_item_choose" v-if="item.isChoose">
                <img src="@/assets/price.png" alt="" />
              </div>
            </div>
          </div>
          <div class="setdialog_footer">
            <div class="setdialog_footer_img">
              <img :src="payInfo.qrcode" style="width: 100%; height: 100%" />
            </div>
            <div style="margin-left: 20px; margin-top: 10px">
              <div class="setdialog_footer_price">
                <div class="setdialog_footer_price_top">应付金额：</div>
                <div class="setdialog_footer_price_center">￥{{ price }}</div>
              </div>
              <div class="setdialog_footer_method">
                <div
                  class="setdialog_footer_method_item"
                  v-for="(item, index) in paymode"
                  :key="index"
                  @click="handleMode(item)"
                >
                  <img :src="item.url" style="margin-top: 4px" />
                  <div>{{ item.name }}</div>
                  <div
                    class="setdialog_footer_method_item_mode"
                    v-if="item.isChoose"
                  >
                    <img src="@/assets/mode.png" />
                  </div>
                </div>
              </div>
            </div>
            <div
              style="margin-left: 148px; margin-top: 10px; cursor: pointer"
              @click="handleCustomer"
            >
              <img src="@/assets/客服.png" />
              联系客服
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="reportDialog" :show-close="false">
      <div class="reportdialog">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="反馈内容:">
            <el-input type="textarea" v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="姓名:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:">
            <el-input v-model="form.mailbox"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSub">提交</el-button>
            <el-button @click="handleCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <LoginDialog
      v-if="loginDialog"
      @close="handleclose"
      @avatar="handleAvatar"
    ></LoginDialog>
    <ServerDialog v-if="serverDialog" @close="handleServerClose"></ServerDialog>
    <div class="drover" @click="handleOpenDrawer">
      <i class="el-icon-s-unfold"></i>
    </div>
    <div class="drawer">
      <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :with-header="false"
        direction="ltr"
        size="50%"
      >
        <div class="padding-top:20px;height:100vh;">
          <div
            v-for="(item, index) in classification"
            :key="index"
            class="vStyle"
            @click="handleCLick(item, index)"
          >
            <div>{{ item.name }}</div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  getProductInfoAPI,
  getProductdetailsAPI,
  feedback,
  getOrderComboAPI,
  orderAddAPI,
  getOrderStatusAPI,
} from "@/api/index.js";

export default {
  data() {
    return {
      drawer: false,
      classification: [],
      name: "",
      visibleMaskId: null,
      details: {},
      dialogVisible: false,
      title: "",
      describe: "",
      keyword: "",
      avatar: "",
      id: "",
      pname: "",
      isHidden: false,
      reportDialog: false,
      loginDialog: false,
      serverDialog: false,
      form: {
        name: "",
        phone: "",
        mailbox: "",
        title: "",
      },
      nickname: "",
      priceData: [],
      price: "",
      combo_id: "",
      payInfo: {},
      type: "wechat",
      paymode: [
        {
          url: require("../../assets/wechat.png"),
          name: "微信支付",
          isChoose: true,
          type: "wechat",
        },
        {
          url: require("../../assets/alipay.png"),
          name: "支付宝",
          isChoose: false,
          type: "alipay",
        },
      ],
      timer: "",
      isMobile: false,
    };
  },
  watch: {
    $route(to, from) {
      this.pname = this.$route.query.pname;
      this.name = this.$route.query.name;
      if (!this.$route.query.desc) {
        this.getProductInfo();
      }
    },
  },
  metaInfo: function () {
    return {
      title: this.title || this.details.name,
      meta: [
        { name: "describe", content: this.describe },
        { name: "keyword", content: this.keyword },
      ],
    };
  },

  created() {
    this.pname = this.$route.query.pname;
    this.name = this.$route.query.name;
    console.log("11111");
    this.getProductInfo();
    this.avatar = localStorage.getItem("avatar");
    this.nickname = localStorage.getItem("nickname");
    this.id = localStorage.getItem("id");
    this.checkDevice();
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    checkDevice() {
      const width = window.innerWidth;
      this.isMobile = width <= 768;
    },
    handleJumpUser() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/userinfo",
        });
      } else {
        this.loginDialog = true;
      }
    },
    handleServerClose() {
      this.serverDialog = false;
    },
    handleCustomer() {
      this.serverDialog = true;
    },
    async handleMode(item) {
      this.paymode.forEach((item) => {
        item.isChoose = false;
      });
      item.isChoose = true;
      this.type = item.type;
      const resp = await orderAddAPI({
        name: this.$route.query.name,
        pay_price: this.price,
        pay_type: "pc",
        type: this.type,
        combo_id: this.combo_id,
      });
      this.payInfo = resp.data;
    },
    async handleClickPrice(item, type) {
      console.log(type);

      this.priceData.forEach((item) => {
        item.isChoose = false;
      });
      item.isChoose = !item.isChoose;
      this.$forceUpdate();
      this.priceData.forEach((item) => {
        if (item.isChoose) {
          this.price = item.price;
          this.combo_id = item.id;
        }
      });
      const resp = await orderAddAPI({
        name: this.$route.query.name,
        pay_price: this.price,
        pay_type: "pc",
        type: this.type,
        combo_id: this.combo_id,
      });
      this.payInfo = resp.data;
    },
    handleAvatar(avatar) {
      this.avatar = avatar;
    },
    handleclose(mode) {
      this.loginDialog = mode;
    },
    handleReportDialog() {
      this.reportDialog = true;
    },
    handeleScrollNew(event) {
      console.log(event.target.scrollTop);
      const boxScrollTop = event.target.scrollTop;
      if (boxScrollTop > 200) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }
    },
    handleScroll() {
      if (window.scrollY > 800) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }
    },
    handleOpen(id) {},
    async getProductInfo() {
      const res = await getProductInfoAPI({
        id: this.$route.query.id,
      });
      this.classification = res.data;
      // for (const item of this.classification) {
      //   item.year = item.name.subString(0, 9);
      //   item.newVersion = item.name.subString(10, 13);
      // }
      if (this.$route.query.id) {
        this.getProductdetails(this.$route.query.id);
      } else {
        this.getProductdetails(this.classification[0].id);
      }
    },
    async getProductdetails(id) {
      const res = await getProductdetailsAPI({
        id,
      });
      this.details = res.data;
      this.title = this.details.title;
      this.describe = this.details.describe;
      this.keyword = this.details.keyword;
      this.scrollToTop();
    },
    showMask(id) {
      this.visibleMaskId = id;
    },
    hideMask() {
      this.visibleMaskId = null;
    },
    isMaskVisible(id) {
      return this.visibleMaskId === id;
    },
    handleCLick(item, index) {
      var route = this.$route;
      item.newname = item.name.replace(" ", "_");
      if (!item.isChecked) {
        this.$router.replace({
          path: "/product",
          query: { ...route.query, desc: encodeURIComponent(item.newname) },
        });

        this.classification.forEach((i, idx) => {
          if (item == i) {
            i.isChecked = true;
          } else {
            i.isChecked = false;
          }
        });
      }
      // this.classification.forEach((i, idx) => {
      //   if (item == i) {
      //     i.isChecked = true;
      //     this.$router.replace({
      //       path: "/product",
      //       query: { ...route.query, desc: item.name },
      //     });
      //   } else {
      //     i.isChecked = false;
      //   }
      // });

      this.getProductdetails(item.id);
      this.drawer = false;
    },
    handledown() {
      window.open(this.details.down_url, "_blank");
    },
    async handleSet() {
      if (localStorage.getItem("token")) {
        try {
          this.dialogVisible = true;
          const res = await getOrderComboAPI();
          this.priceData = res.data;
          this.priceData.forEach((item, index) => {
            if (index === 0) {
              this.price = item.price;
              this.combo_id = item.id;
            }
            item.isChoose = false;
          });
          this.priceData[0].isChoose = true;
          this.$forceUpdate();
          const resp = await orderAddAPI({
            name: this.$route.query.name,
            pay_price: this.priceData[0].price,
            pay_type: "pc",
            type: this.type,
            combo_id: this.priceData[0].id,
          });
          this.payInfo = resp.data;
          this.timer = setInterval(async () => {
            const response = await getOrderStatusAPI({
              orderId: this.payInfo.order_id,
            });
            if (response.code === 1) {
              clearInterval(this.timer);
              this.$router.push({
                path: "/userinfo",
              });
            }
          }, 1000);
        } catch (error) {
          if (error.response.data.code === 401) {
            this.$message.error("登录状态失效,请重新登录");
            this.dialogVisible = false;
            this.loginDialog = true;
          }
        }
      } else {
        this.loginDialog = true;
      }
    },
    handleDialogClose() {
      clearInterval(this.timer);
      this.dialogVisible = false;
      this.type = "wechat";
      this.paymode.forEach((item) => {
        item.isChoose = false;
        if (item.name === "微信支付") {
          item.isChoose = true;
        }
      });
      this.$forceUpdate();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleCancel() {
      this.reportDialog = false;
      this.form = {
        name: "",
        phone: "",
        mailbox: "",
        title: "",
      };
    },
    async handleSub() {
      if (!this.form.title) {
        this.$message.error("请填写举报内容");
      } else {
        if (localStorage.getItem("token")) {
          const res = await feedback(this.form);
          if (res.code === 1) {
            this.$message.success("提交成功!");
            this.handleCancel();
          }
        } else {
          this.loginDialog = true;
        }
      }
    },
    handleOpenDrawer() {
      this.drawer = true;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  display: flex;
  background-color: #f8f8f8;
}
.content {
  box-sizing: border-box;
  // width: 100%;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px;
  display: flex;
  overflow-y: scroll;
  // height: 900px;
  width: calc(100vw - 200px);
  height: 100vh;
}
.content_left {
  position: fixed;
  height: calc(100vh - 40px);
  width: 21%;
  background-color: #fff;
  border-radius: 10px;
}
.content_right {
  width: 72%;
  margin-left: calc(21% + 80px);
}
.content_right_top {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 290px;
  background-color: #fff;
  border-radius: 10px;
  font-family: fangyuan;
  padding-top: 10px;
}
.content_right_top_img {
  width: 100px;
  height: 100px;
}
.content_right_top_scroll {
  width: 65%;
  height: 113px !important;
  position: fixed;
  top: 0;
  height: 200px;
  padding: 10px;
  .details_intro {
    display: none;
  }
  .down {
    margin-top: -60px;
    margin-left: 142px;
  }
  .content_right_top_bottom {
    display: none;
  }
  .content_right_top_img {
    width: 50px;
    height: 50px;
  }
  .img_box {
    width: 100px;
    height: 100px;
  }
  .details_name {
    font-size: 25px;
  }
  .down_load {
    width: 150px;
    height: 55px;
    font-size: 25px;
    line-height: 55px;
  }
  .set {
    width: 150px;
    height: 55px;
    font-size: 25px;
    line-height: 55px;
    margin-left: 28px;
  }
}
.content_right_bottom {
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 10px;
  margin-top: 15px;
  width: 100%;
  min-height: 400px;
  background-color: #fff;
  border-radius: 10px;
}
.content_right_bottom_scroll {
  margin-top: 300px;
}
.content_right_bottom::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
.content_left_top {
  height: 80px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #4981ff;
  padding: 23px 28px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 36px;
  font-family: fangyuan;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content_left_top_route {
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-items: center;
}
.content_left_top_route_detaildname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.version_item {
  box-sizing: border-box;
  // height: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 28px;
  cursor: pointer;
  border-bottom: 1px solid#cccccc5e;
}
.version_item_hover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1));
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.version {
  height: calc(100vh - 180px);
  overflow-y: scroll;
}
// .version::-webkit-scrollbar {
//   display: none; /* 隐藏滚动条 */
// }
.version_item_left {
  width: 50%;
}
.version_item_right {
  width: 50%;
  margin-left: 15px;
  font-size: 30px;
  font-family: fangyuan;
  font-weight: bold;
}
.version_item_right_bottom {
  font-size: 16px;
  font-family: fangyuan;
  color: #939393;
}
.version_item_right_top {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.img_box {
  width: 160px;
  height: 130px;
  background-color: #eef2fb;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_right_top__title {
  padding: 0 20px;
  display: flex;
}
.info_box {
  width: 800px;
  margin-left: 20px;
}
.details_name {
  font-weight: bold;
  font-size: 34px;
}
.details_intro {
  font-size: 18px;
  line-height: 30px;
  color: #86929e;
}
.down {
  margin-left: 193px;
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
}
.down_load {
  cursor: pointer;
  width: 170px;
  height: 55px;
  border-radius: 10px;
  background-color: #417fff;
  font-size: 24px;
  text-align: center;
  line-height: 55px;
  color: #fff;
}
.set {
  cursor: pointer;
  width: 170px;
  height: 55px;
  border-radius: 10px;
  border: 1px solid #000;
  font-size: 24px;
  text-align: center;
  line-height: 55px;
  margin-left: 52px;
}
.content_right_top_bottom {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  background-color: #f3f3f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #9ba3ae;
  padding: 10px 60px;
}

.new_content_right_top_bottom {
  box-sizing: border-box;
  width: 100%;
  // height: 70px;
  background-color: #f3f3f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #9ba3ae;
  padding: 10px 60px 10px 60px;
}
table {
  font-weight: normal;
  font-family: fangyuan;
  border-collapse: collapse;
  width: 100%;
  color: #85898c;
}
tr {
  font-weight: normal;
}
th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
.footer {
  position: fixed;
  right: 0;
  top: 50%;
  width: 70px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2px 0 #ccc;
}
.footer__item {
  box-sizing: border-box;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  padding: 5px;
  color: #9fa7af;
  cursor: pointer;
}
.footer__item:hover {
  background-color: #4981ff;
  color: #fff;
}
.footer__item:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.footer__item:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.imgstyle {
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.reportdialog {
  width: 500px;
  height: 270px;
  background-color: #fff;
  margin-left: -280px;
  padding: 100px 50px;
  padding-top: 80px;
  border-radius: 10px;
}
.setdialog {
  position: absolute;
  top: 0;
  right: -400px;
  width: 809px;
  height: 488px;
  background-color: #fff;
  z-index: 99999;
  border-radius: 15px;
  display: flex;
}
.setdialog_left {
  width: 220px;
  background: url(../../assets/左侧背景.png);
  background-repeat: no-repeat;
}
.setdialog_left_img {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.setdialog_left_text {
  font-size: 14px;
  color: #fff;
  font-family: fangyuan;
  margin-top: 110px;
  margin-left: 30px;
}
.setdialog_left_text_item {
  margin-top: 25px;
}
.setdialog_right {
  margin-left: 20px;
  padding: 20px;
  width: 90%;
}
.setdialog_right_top_text {
  font-family: fangyuan;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}
.setdialog_right_top_text_arrow {
  font-size: 14px;
  background-image: linear-gradient(to right, #1246d7, #5398ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: flex;
  align-items: flex-end;
}
.user_info {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: fangyuan;
}
.price {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.price_item {
  position: relative;
  width: 142px;
  height: 149px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.price_item_top {
  font-size: 22px;
  color: #145dff;
  font-family: fangyuan;
  font-weight: bold;
}
.price_item_center {
  font-size: 16px;
  color: #145dff;
  font-family: fangyuan;
  font-weight: bold;
}
.price_item_center {
  margin-top: 20px;
}
.price_item_bottom {
  font-size: 16px;
  font-family: fangyuan;
  font-weight: bold;
  color: #000;
}
.price_item_bottom_item {
  margin-top: 10px;
}
.price_item_choose {
  position: absolute;
  top: -3px;
  left: -4px;
}
.setdialog_footer {
  box-sizing: border-box;
  width: 100%;
  height: 158px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
}
.setdialog_footer_img {
  width: 140px;
  height: 140px;
}
.setdialog_footer_price {
  display: flex;
}
.setdialog_footer_price_top {
  font-size: 18px;
  color: #ff4a55;
  font-family: fangyuan;
  font-weight: bold;
}
.setdialog_footer_price_center {
  font-size: 20px;
  color: #ff4a55;
  font-family: fangyuan;
  font-weight: bold;
}

.setdialog_footer_method_item {
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  width: 117px;
  height: 27px;
  display: flex;
  border: 2px solid #ccc;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
.setdialog_footer_method_item_mode {
  position: absolute;
  top: -1px;
  left: -3px;
}
.drover {
  display: none;
}
.ismobile {
  width: 100%;
}

@media (max-width: 768px) {
  .ismobile {
    max-width: 430px;
  }
  .content {
    height: auto;
    padding: 0;
  }
  .content_left {
    display: none;
  }
  .footer {
    display: none;
  }
  .content_right_top__title {
    flex-direction: column;
  }
  .img_box {
    width: 100%;
  }
  .content_right_top {
    width: 100%;
    height: auto;
  }
  .content_right_top_bottom {
    position: static;
  }
  .info_box {
    margin: 0;
    width: 100%;
  }
  .content_right_top_bottom {
    height: auto;
  }
  .down {
    margin: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .set {
    margin: 0;
    margin-top: 20px;
    display: none;
  }
  .content_right {
    width: 100%;
    margin: 0;
  }
  .content_right_bottom {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 0;
  }
  .content_right_top_scroll {
    height: 100px !important;
    .content_right_top__title {
      display: none;
    }
    .down {
      margin: 0;
      flex-direction: row;
      .set {
        margin: 0;
        margin-left: 12px;
      }
    }
    .content_right_top_scroll .down {
      margin: 0;
    }
  }
  .drover {
    display: block;
    position: fixed;
    top: 20px;
    left: 10px;
    font-size: 35px;
    color: #417fff;
    font-family: fangyuan;
    background-color: #fff;
    border-radius: 5px;
  }
  .drawer {
    display: block;
  }
  .vStyle {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    font-family: fangyuan;
    color: #85898c;
  }
  .report_move {
    display: none;
  }
  .el-drawer__body {
    padding-top: 20px;
  }
}
</style>
