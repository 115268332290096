<template>
  <div>
    <el-dialog
      :visible.sync="loginDialog"
      ref="loginRef"
      :show-close="false"
      :before-close="handleclose"
    >
      <div class="login">
        <img src="@/assets/登录.png" />
        <div style="position: absolute; top: 144px; left: 528px">
          <img :src="login.url" style="width: 170px; height: 170px" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { trainingInRotation, getQrcodeAPI } from '@/api/index.js'
export default {
  name: 'LoginDialog',
  data () {
    return {
      loginDialog: false,
      login: {}
    }
  },
  created () {
    this.handleLogin()
  },
  methods: {
    handleOpen () {
      this.loginDialog = true
    },
    handleclose (avatar) {
      clearInterval(this.timer)
      this.loginDialog = false
      this.$emit('close', false)
      this.$emit('avatar',avatar )
    },
    timermethod (mode) {
      const that = this
      this.timer = setInterval(async () => {
        const resp = await trainingInRotation({
          ticket: that.login.ticket
        })
        if (resp.code === 1) {
          this.$message.success('登录成功!')
          localStorage.setItem('token', resp.data.token)
          localStorage.setItem('avatar', resp.data.avatar)
          localStorage.setItem('id', resp.data.id)
          localStorage.setItem('nickname', resp.data.nickname)
          this.handleclose(resp.data.avatar)
        }
      }, 1000)
    },
    async handleLogin () {
      const res = await getQrcodeAPI({
        wechat: 'pc'
      })
      this.loginDialog = true
      this.login = res.data
      this.timermethod()
    }
  }
}

</script>

<style></style>
