<template>
  <div class="userinfo">
    <div class="userinfo_top">
      当前位置：首页<i class="el-icon-arrow-right"></i>个人中心
    </div>
    <div class="userinfo_info">
      <div class="userinfo_info_left">
        <div class="userinfo_info_left">
          <img src="@/assets/矩形.png" />
        </div>
        <div class="userinfo_info_left_right">
          <div class="userinfo_info_left_top">{{ nickname }}</div>
          <div class="userinfo_info_left_bottom">ID:{{ id }}</div>
        </div>
      </div>
      <div class="userinfo_info_right">
        <div class="order_total" style="margin-left: 37px">
          <div class="order_total_top">订单总个数</div>
          <div class="order_total_bottom">
            <span class="order_total_left">{{ count }}</span>
            <span class="order_total_right">个</span>
          </div>
        </div>
        <div class="order_total" style="margin-left: 37px">
          <div class="order_total_top">已完成服务</div>
          <div class="order_total_bottom">
            <span class="order_total_left">{{ status }}</span>
            <span class="order_total_right">次</span>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div style="min-width: 16%; text-align: center">订单时间</div>
      <div style="min-width: 16%; text-align: center">服务项目</div>
      <div style="min-width: 16%; text-align: center">订单编号</div>
      <div style="min-width: 16%; text-align: center">支付金额</div>
      <div style="min-width: 16%; text-align: center">订单状态</div>
      <div style="min-width: 16%; text-align: center">订单操作</div>
    </div>
    <div class="order">
      <div class="order_item" v-for="(item, index) in orderList" :key="index">
        <div class="order_item_column">{{ item.add_time }}</div>
        <div class="order_item_column">{{ item.name }}</div>
        <div class="order_item_column">{{ item.order_id }}</div>
        <div class="order_item_column">{{ item.pay_price }}</div>
        <div class="order_item_column">{{ item.status }}</div>
        <div class="order_item_column">
          <div><img src="@/assets/客服图标.png" /></div>
          <div style="margin-left: 10px" @click="handleServer">
            联系人工客服
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          layout="prev, pager, next"
          :total="count"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <ServerDialog v-if="serverDialog" @close="handleServerClose"></ServerDialog>
    <LoginDialog
      v-if="loginDialog"
      @close="handleclose"
      @avatar="handleAvatar"
    ></LoginDialog>
  </div>
</template>

<script>
import { getOrderListAPI } from '@/api/index.js'
export default {
  data () {
    return {
      nickname: '',
      id: '',
      orderList: [],
      count: 0,
      status: '',
      serverDialog: false,
      form: {
        page: 1,
        pay_status:1
      },
      loginDialog: false
    }
  },
  created () {
    this.nickname = localStorage.getItem('nickname')
    this.id = localStorage.getItem('id')
    this.getOrderList()
  },
  methods: {

    handleAvatar (avatar) {
      this.avatar = avatar
    },
    async getOrderList () {
      try {
        const res = await getOrderListAPI(this.form)
        this.orderList = res.data.list
        this.count = Number(res.data.count)
        this.status = res.data.status
      } catch (error) {
        if (error.response.data.code === 401) {
          this.$message.error('登录状态失效,请重新登录')
          this.loginDialog = true
        }
      }
    },
    handleServer () {
      this.serverDialog = true
    },
    handleServerClose () {
      this.serverDialog = false
    },
    handleCurrentChange (current) {
      this.form.page = current
      this.getOrderList()
    },
    handleclose (mode) {
      this.getOrderList()
      this.loginDialog = mode

    },
  }
}
</script>

<style scoped>
div {
  box-sizing: border-box;
}
.userinfo {
  background-color: #fff;
  padding: 20px;
  margin-left: 20px;
  border-left: 1px solid #ccc;
}
.userinfo_top {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  color: #9fa7af;
}
.content_top {
  width: 100%;
  font-size: 12px;
  color: #9fa7af;
  font-family: fangyuan;
}
.userinfo_info {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.userinfo_info_left {
  display: flex;
  align-items: center;
  font-family: fangyuan;
  font-weight: bold;
}
.userinfo_info_left_right {
  margin-left: 20px;
  display: flex;
  font-size: 40px;
  flex-direction: column;
  justify-content: center;
}
.userinfo_info_left_bottom {
  font-size: 25px;
  margin-top: 20px;
}
.userinfo_info_right {
  display: flex;
  justify-content: flex-end;
}
.order_total {
  color: #165fff;
  font-family: fangyuan;
  width: 244px;
  height: 228px;
  border: 1px solid #8888885c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
}
.order_total_top {
  font-size: 16px;
  font-weight: bold;
}
.order_total_bottom {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.order_total_left {
  font-size: 60px;
  color: black;
}
.order_total_right {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.column {
  margin-top: 40px;
  display: flex;
  background-color: #145dff;
  font-family: fangyuan;
  color: #fff;
  padding: 20px 100px;
  border-radius: 50px;
  justify-content: space-between;
}
.order {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 0 0 5px 0 #ccc;
  min-height: 460px;
}
.order_item {
  margin-top: 20px;
  display: flex;
  font-family: fangyuan;
  padding: 20px 100px;
  border-radius: 50px;
  justify-content: space-between;
}
.order_item_column {
  min-width: 16%;
  text-align: center;
}
.order_item_column:nth-last-of-type(1) {
  display: flex;
  color: #145dff;
  cursor: pointer;
  justify-content: center;
}
.pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
</style>
