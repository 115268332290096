<template>
  <div>
    <el-dialog
      :visible.sync="serverDialog"
      ref="dialog"
      :show-close="false"
      @close="handleclose"
    >
      <div class="server">
        <img src="@/assets/联系客服.png" />
        <div style="position: absolute; top: 292px; left: -31px">
          <img src="@/assets/售前二维码.png" style="width: 170px; height: 170px" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ServerDialog',
  data () {
    return {
      serverDialog: false,
      login: {}
    }
  },
  created () {
    this.serverDialog = true
  },
  methods: {
    handleclose () {
      this.serverDialog = false
      this.$emit('close', false)
    }

  }
}

</script>

<style scoped>
.server{
  margin-left: -214px;
}
</style>
