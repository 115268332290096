import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/fonts/font.css'
import VueMeta from 'vue-meta'
import LoginDialog from '@/components/login.vue'
import ServerDialog from '@/components/server.vue'
const wx = window.wx
if (!wx) {
  document.write('<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>')
}
// import dataV from '@jiaminghi/data-view'
// Vue.use(dataV)

import VScaleScreen from 'v-scale-screen'

Vue.use(VScaleScreen)
Vue.component('LoginDialog', LoginDialog)
Vue.component('ServerDialog', ServerDialog)
Vue.use(VueMeta)
Vue.use(ElementUI)
Vue.use(VueCookies)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
